import React from "react";
import DashboardHeader from "../../components/dashboardHeader/DashboardHeader";
import DashboardNavbar from "../../components/dashboardnavbar/DashboardNavbar";
import "./UserDashboard.css";
import "../../components/dashboardStyle/DashboardStayle.css";

const UserDashboard: React.FC = () => {
  const userName: string = "Ciroma";
  return (
    <main className="dashboard__container">
      <DashboardNavbar activeItem="Dashboard" />
      <div className="content__section">
        <DashboardHeader headTitle={`Hello, ${userName}`} />
        <div>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Dicta
          nesciunt, sequi accusantium possimus nobis repudiandae, magnam
          doloribus quaerat exercitationem quam rem vel porro delectus vitae
          molestiae ex rerum alias. Labore quos commodi eius maxime
          exercitationem praesentium repudiandae suscipit officia id incidunt,
          eaque reiciendis. Recusandae vitae obcaecati eum quia commodi quas
          voluptas quae nam sunt cupiditate dolores iusto magnam labore nihil
          placeat odio veritatis beatae rem architecto hic voluptatem, corrupti
          esse! Neque non sapiente assumenda sunt voluptas! Culpa ullam quidem,
          atque deserunt molestiae consectetur excepturi qui rerum soluta vel
          veniam tempore voluptate animi ea saepe doloremque odit, inventore at!
          Ab sunt, officiis molestias facilis ea modi harum. Eum exercitationem,
          minima laudantium, cupiditate dolore eligendi velit id soluta cum,
          dolor nemo qui odio accusantium asperiores saepe? Nemo iusto culpa
          deserunt harum explicabo? Excepturi hic possimus quod, odit nobis
          officiis atque aliquam nostrum, minus dicta explicabo velit inventore,
          necessitatibus sit perferendis totam neque sunt vero dolores
          reprehenderit harum fugiat consequuntur in laboriosam? Minus voluptas
          quaerat consectetur expedita ex, fugiat eius commodi non velit,
          architecto tenetur repellendus placeat tempore reprehenderit fuga
          ullam amet quis repellat? Debitis iusto consequatur, facere hic quae
          autem possimus eaque dignissimos quos amet totam nobis dolorum
          similique ratione consectetur, inventore dolores sed voluptate! Optio
          deleniti nostrum neque fugiat accusantium eius laudantium quaerat, ut
          blanditiis exercitationem vitae doloribus earum at corrupti ad!
          Dolorem unde modi beatae, quis vero quo, vel blanditiis error quod,
          accusamus placeat nam maiores obcaecati dolor repellendus quaerat
          facere odio inventore? Magnam vel eius iusto porro nostrum, odit ipsa
          enim in alias quae consequuntur a doloribus quas accusantium
          praesentium voluptas libero veritatis veniam? Perspiciatis, ipsam
          minima. Qui neque tenetur mollitia repellat assumenda aliquam
          reiciendis ut quasi, illo eos, ipsum vitae, veritatis modi itaque
          officia exercitationem vero consequatur excepturi sunt unde! Beatae,
          eligendi pariatur? Eveniet et error praesentium dolor porro culpa
          nesciunt tenetur distinctio adipisci pariatur, rem facere eligendi
          ullam aperiam consequuntur ipsa? Sit optio, et quo aut accusantium
          quos iusto necessitatibus, deserunt libero voluptatibus iste quia
          impedit quas doloremque architecto laborum eligendi corporis suscipit.
          Rerum porro, aperiam pariatur totam, officiis dicta voluptas, quos quo
          dolorem laborum deserunt sapiente. Voluptas labore libero similique
          reiciendis. Adipisci, quidem sit minima soluta, totam esse fuga quam
          animi ipsam eaque sed laborum vero modi reiciendis ipsum rem earum
          neque id nemo. Libero excepturi aliquid repellat reprehenderit unde
          pariatur, nisi est distinctio sit numquam sint quidem impedit dolore
          necessitatibus ipsum, harum ea modi. Iusto nam animi cum quisquam
          saepe fugiat deserunt maiores atque. Animi quae tenetur aut error
          cupiditate vitae sequi laborum quasi vero soluta est possimus, debitis
          quod ab, temporibus cum maxime beatae officiis asperiores, accusantium
          consectetur incidunt? Sed, explicabo perferendis nesciunt libero est
          voluptas, voluptate tenetur ipsum consequatur optio laboriosam
          deserunt repudiandae atque cum repellendus repellat? Libero aliquid
          ullam consequatur ipsa inventore ut perspiciatis iste nostrum quo in
          quod fugiat voluptate commodi nulla at fuga voluptatum illum, expedita
          repudiandae, pariatur debitis quia, assumenda omnis amet! Culpa fugit
          aliquam vitae laudantium sit saepe sapiente nostrum assumenda. Beatae
          neque unde sint, quibusdam, itaque eos quasi, minima illo soluta natus
          delectus animi temporibus expedita dolores pariatur impedit.
          Consectetur dicta nemo delectus iure voluptatibus maxime ducimus,
          laboriosam recusandae aperiam sint architecto totam eaque laborum,
          esse nam qui quos. Quaerat accusamus aspernatur minus laboriosam
          debitis laborum? Reprehenderit illo voluptatibus ullam eaque, est
          fugit necessitatibus perspiciatis? Laborum enim commodi, veritatis ex
          illo expedita dolorum inventore placeat quas, suscipit praesentium
          ipsam tenetur iste totam dolorem explicabo ullam voluptatem, nam et?
          Maiores eligendi dolore facilis est sapiente reiciendis placeat ullam.
          Voluptatibus aliquid molestiae, a excepturi, nesciunt sed tempora,
          officia illum voluptate ipsum nulla sunt numquam porro magni. Facilis
          ea assumenda delectus? Explicabo fugit dolor eligendi similique,
          harum, et temporibus, iure rerum officia consequuntur unde
          reprehenderit consequatur accusamus perferendis totam vel non.
          Perferendis illum nam accusantium magnam, deserunt debitis repellat
          iure perspiciatis dolores error iusto, in animi ea aut nemo, quia
          doloribus non! Commodi quasi incidunt sunt doloremque dolorum?
          Adipisci consequatur possimus accusamus, provident optio repellendus
          necessitatibus eaque voluptatibus nihil quos! Ullam, esse error
          blanditiis rem consequatur tempore quis suscipit aspernatur! Dolore
          impedit labore perferendis nemo consequatur! Deserunt atque dolores
          blanditiis debitis ipsam sed numquam iste explicabo optio architecto,
          dolor aspernatur eveniet. Quaerat eaque non quia ipsa sequi
          voluptatum, excepturi expedita, rem reiciendis architecto neque ipsam
          aspernatur impedit debitis exercitationem distinctio repellat magni
          doloribus necessitatibus quidem. Recusandae, dolor repudiandae nihil
          inventore at laboriosam tenetur deleniti natus doloribus error impedit
          consequuntur maxime pariatur fugit. Necessitatibus quibusdam
          architecto nemo, atque nam sed laudantium fugiat rem incidunt ad
          placeat laborum ratione quas explicabo provident cumque, sapiente hic
          consequatur veritatis. Expedita autem magni perferendis tempora
          nostrum iste consequatur fuga fugit hic? Omnis quod excepturi porro
          blanditiis error voluptatibus eaque praesentium cumque. Omnis hic
          ipsum maiores suscipit, minima laudantium deserunt reprehenderit eum
          quisquam exercitationem quia a? Repellat iusto, nulla, hic deleniti
          sint dolorem earum, non numquam ea dolores necessitatibus vitae id
          aspernatur nostrum quo debitis asperiores voluptatibus ipsa
          reprehenderit corporis incidunt! Est exercitationem magni voluptates
          vel perferendis animi iure id. Corporis exercitationem similique est
          iure, esse sequi neque atque qui modi doloremque error voluptatum eos
          minus hic reprehenderit, saepe rem provident cumque quos molestiae
          blanditiis ad nihil. Sunt similique recusandae perferendis labore
          dolore perspiciatis dolorum? Maiores, dolore. Ducimus deleniti, rem
          laboriosam molestias maiores eaque eos quidem veritatis voluptas fugit
          aspernatur aut fuga iste, voluptatum aliquid facilis ab esse
          repudiandae provident voluptates nemo iure impedit commodi? Minima
          culpa perspiciatis totam consectetur. Dolorem iste molestiae
          repudiandae provident commodi ipsam harum totam aliquam architecto ea
          nam, ullam a nisi quisquam tempore modi minus! Voluptatum earum porro
          dignissimos reiciendis saepe harum numquam? Consectetur, est molestias
          quasi amet harum, placeat ad temporibus aut reiciendis aliquam quidem
          magnam omnis laborum porro. Est consequatur asperiores alias, non
          obcaecati iure quibusdam ut sed reiciendis a dolores ex veniam
          voluptas fugiat excepturi minima at culpa amet iste, maiores voluptate
          velit aliquid similique. Voluptates dolores obcaecati alias excepturi
          aspernatur molestiae maxime numquam assumenda nesciunt ducimus
          reprehenderit exercitationem, cum ipsam suscipit illo tenetur sed
          distinctio quibusdam labore? Placeat quas commodi ipsa quod
          perspiciatis aut nobis? Molestias, nesciunt?
        </div>
      </div>
    </main>
  );
};
export default UserDashboard;
