import React from "react";
import "./Testloading.css";

const Testloading: React.FC = () => {
  return (
    <div className="loading__overlay">
      <div className="loading__line"></div>
    </div>
  );
};

export default Testloading;
